import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import { injectIntl } from 'react-intl';
import Industries from './../../components/industries';
import {LanguageContext} from "../layouts";

const IndustryList = ({ intl, title, showIndustries, showCompanies }) => {
    const { langKey } = useContext(LanguageContext);
    const data = useStaticQuery(graphql`
        query getIndustries {
          allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "industry"}}}) {
            edges {
              node {
                id
                frontmatter {
                  industryName
                  langKey
                  clients {
                    name
                  }
                }
              }
            }
          }
        }
    `);
    const dataFiltered = data.allMarkdownRemark.edges.filter((item) => item.node.frontmatter.langKey === langKey);

    return(
        <Industries title={
            (title)
                ? title
                : (showCompanies && showIndustries)
                    ? intl.formatMessage({id: 'industryListTitle'})
                    : intl.formatMessage({id: 'activeIndustryListTitle'})
            }>
            {(!showCompanies && showIndustries) &&
                <ul>
                    {dataFiltered.map((item, index) => {
                        return (<li key={`${item.node.id}-${index}`}>{item.node.frontmatter.industryName}</li>);
                    })}
                </ul>
            }
            {((showCompanies && showIndustries)) &&
            dataFiltered.map((item) => {
                return (
                    <div key={item.node.id}>
                        <h3>{item.node.frontmatter.industryName}</h3>
                        {item.node.frontmatter.clients &&
                        <ul>
                            {item.node.frontmatter.clients.map((client, index) => {
                                return(
                                    <li key={`${item.node.id}-${index}`}>{client.name}</li>
                                )
                            })}
                        </ul>
                        }
                    </div>
                )
            })
            }
        </Industries>
    );
};

IndustryList.defaultProps = {
    showIndustries: true,
    showCompanies: true,
};

IndustryList.propTypes = {
    intl: PropTypes.object,
    showIndustries: PropTypes.bool,
    showCompanies: PropTypes.bool,
    title: PropTypes.string,
};

export default injectIntl(IndustryList);
