import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl } from 'react-intl';
import {LanguageLayout} from '../containers/layouts';
import Wrapper from './../components/wrapper/';
import PageIntro from './../components/pageIntro/';
import List from './../components/list';
import IndustryList from './../containers/industryList';
import WrapperContent from './../components/wrapperContent/';

export const CasesOverviewTemplate = ({ intl, title, description, cases, casesPerPage }) => (
    <>
        <PageIntro color="sand">
            <Wrapper>
                <WrapperContent>
                    <PageIntro.title>{title}</PageIntro.title>
                    <PageIntro.description html>{description}</PageIntro.description>
                </WrapperContent>
            </Wrapper>
        </PageIntro>
        <Wrapper>
            <WrapperContent>
                <List
                    data={cases}
                    pageSize={casesPerPage || 10}
                    categoryMainLabel={intl.formatMessage({id: 'cases.listCategoryMain'})}
                    showImage={true}
                />
            </WrapperContent>
            <WrapperContent color="blue">
                <IndustryList />
            </WrapperContent>
        </Wrapper>
    </>
);

export const CasesOverviewTemplateIntl = injectIntl(CasesOverviewTemplate);

const CasesOverview = (props) => {
    const {frontmatter, fields } = props.data.markdownRemark;
    const cases = props.data.allMarkdownRemark.nodes.map( (caseItem) => {
        return {
            id: caseItem.id,
            title: caseItem.frontmatter.title,
            categoryMain: caseItem.frontmatter.industry,
            image: (caseItem.frontmatter.listimage && caseItem.frontmatter.listimage.childImageSharp) ? caseItem.frontmatter.listimage.childImageSharp.fluid : null,
            slug: caseItem.fields.slug,
        }
    });

    return (
        <LanguageLayout location={props.location}>
            <CasesOverviewTemplateIntl
                title={frontmatter.title}
                description={fields.frontmattermd.pageDescriptionText.html}
                cases={cases}
                casesPerPage={frontmatter.casesItemsPerPage}
            />
        </LanguageLayout>
    );
};

export default CasesOverview;

export const pageQuery = graphql`
  query CasesOverviewTemplate($langKey: String!) {
    markdownRemark(fields: {langKey: {eq: $langKey}}, frontmatter: {templateKey: {eq: "cases-overview"}}) {
        fields {
                frontmattermd {
                    pageDescriptionText {
                        html
                    }
                }
            }
        frontmatter {
          title
          casesItemsPerPage
        }
      }
      allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "case-page"}}, fields: {langKey: {eq: $langKey}}}) {
        totalCount
        nodes {
          id
          frontmatter {
            industry
            title
            listimage {
              id
              publicURL
              childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
            }
          }
          fields {
            slug
            langKey
          }
        }
      }
  }
`;
