import React from 'react';
import PropTypes from 'prop-types';
import style from './industries.module.scss';

const Industries = ({ children, className, title, ...rest}) => (
    <div className={[style.industries, className].join(' ')}{...rest}>
        {title &&
        <h2 className={style.title}>{title}</h2>
        }
        {children}
    </div>
);

Industries.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    title: PropTypes.string,
};

export default Industries;
